<script lang="ts" setup>

const canvasRef = ref()
const videoRef = ref<HTMLVideoElement>()
const currentCamera = ref<string>()
const { videoInputs: cameras } = useDevicesList({
  requestPermissions: true,
  onUpdated() {
    if (!cameras.value.find(i => i.deviceId === currentCamera.value))
      currentCamera.value = cameras.value[0]?.deviceId
  },
})

const { stream, enabled } = useUserMedia({
  constraints: { video: { deviceId: currentCamera } },
})
watchEffect(() => {
  if (videoRef.value)
    videoRef.value.srcObject = stream.value!
})
watch(() => globalActiveKey.value, () => {
  videoRef.value?.pause()
})
function onVideoPlayDetect() {
  detectVideo(videoRef.value!, canvasRef.value)
}

</script>

<template>
  <div class="mt-10px">
    <ASpace class="mb-10px">
      <Button @click="enabled = !enabled" class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
        {{ enabled ? 'ปิดกล้อง' : 'เปิดกล้อง' }}
      </Button>
    </ASpace>
    <div>
      <div
        v-for="camera of cameras"
        :key="camera.deviceId"
        class="cursor-pointer px-2 py-1"
        :class="{ 'text-blue-6': currentCamera === camera.deviceId }"
        @click="currentCamera = camera.deviceId"
      >
        {{ camera.label }}
      </div>
    </div>
    <div v-show="enabled" class="relative h-full w-full">
      <video ref="videoRef" class="h-full w-full" controls autoplay @play="onVideoPlayDetect" playsinline />
      <canvas ref="canvasRef" class="pointer-events-none absolute top-0 h-full w-full" :width="inputShape[1]" :height="inputShape[2]" />
    </div>
  </div>
</template>
