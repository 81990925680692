<script setup lang="ts">
import { globalActiveKey } from '~/composables'

onMounted(() => {
  loadTfModels()
})
</script>

<template>
  <div>
    <a-typography :style="{ marginTop: '-40px' }">
      <a-typography-title>
        AI ทดสอบตรวจจับวัตถุ
      </a-typography-title>
      <ASpin :loading="tfjsLoading" class="container mx-auto flex justify-center" :tip="tfjsSpinTip">
        <a-tabs v-model:active-key="globalActiveKey" lazy-load>
          <a-tab-pane key="1" title="กล้อง">
            <TabsWebCam />
          </a-tab-pane>
          <a-tab-pane key="2" title="บันทึกภาพ">
            <TabsRecord />
          </a-tab-pane>
          <a-tab-pane key="3" title="วิดีโอ">
            <TabsVideo />
          </a-tab-pane>
          <a-tab-pane key="4" title="รูปภาพ">
            <TabsPicture />
          </a-tab-pane>
          <a-tab-pane key="5" title="WebRTC">
            <TabsWebRTC />
          </a-tab-pane>
        </a-tabs>
      </ASpin>
    </a-typography>
  </div>
</template>
