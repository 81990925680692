<script lang="ts" setup>
import { useFileSystemAccess } from '@vueuse/core'

const canvasRef = ref()
const videoRef = ref<HTMLVideoElement>()
const dataType = ref('Blob') as Ref<'Text' | 'ArrayBuffer' | 'Blob'>
const videoUrl = ref('')
const { isSupported, file, open } = useFileSystemAccess({
  dataType,
  types: [{
    description: 'Video files',
    accept: {
      'video/*': ['.mp4', '.webm', '.mkv', '.avi', '.mov'],
    },
  }],
  excludeAcceptAllOption: true,
})
watch(() => file.value, () => {
  if (file.value)
    videoUrl.value = URL.createObjectURL(file.value)
})
onMounted(() => {
  if (!isSupported.value)
    alert('เบราว์เซอร์นี้ไม่รองรับ แนะนำให้เปิดกับ google chrome บนคอม !')
})

watch(() => globalActiveKey.value, () => {
  const url = videoUrl.value
  URL.revokeObjectURL(url)
  videoUrl.value = ''
  videoRef.value?.pause()
})
function onVideoPlayDetect() {
  //
  detectVideo(videoRef.value!, canvasRef.value)
}
</script>

<template>
  <div class="mt-10px">
    <ASpace class="mb-10px">
      <Button @click="open()" class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
        เลือกไฟล์ในเครื่อง
      </Button>
    </ASpace>

    <div class="relative h-full w-full">
      <video v-show="videoUrl" ref="videoRef" class="h-full w-full" :src="videoUrl" controls autoplay @play="onVideoPlayDetect" playsinline />
      <canvas ref="canvasRef" class="absolute top-0 w-full h-full pointer-events-none" :width="inputShape[1]" :height="inputShape[2]" />
    </div>
  </div>
</template>
