<template>
    <div class="mt-10px">
        <ASpace class="mb-10px">
        <button id="btn-start-recording" :disabled="disabled" @click="startRec" class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">เปิดกล้องเพื่อบันทึกวิดีโอ</button>
        <button
            class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            id="btn-stop-recording"
            :disabled="!disabled"
            @click="stopRec"
        >ปิดกล้องและหยุดบันทึกวิดีโอ</button>
        </ASpace>
        <div v-if="showvideo" class="relative h-full w-full">
            <video controls autoplay playsinline ref="video" height="500" width="700" class="h-full w-full"></video>
        </div>
    </div>
  </template>
  
  <script>
  import RecordRTC from "recordrtc";
  export default {
    name: "VideoRecord",
    data() {
      return {
        showvideo: false,
        recorder: null,
        disabled: false
      };
    },
    mounted() {},
    methods: {
      startRec() {
        this.disabled = true;
        this.showvideo = true;
        this.captureCamera(camera => {
          const video = this.$refs["video"];
          video.muted = true;
          video.volume = 0;
          video.srcObject = camera;
          this.recorder = RecordRTC(camera, {
            type: "video"
          });
          this.recorder.startRecording();
          // release camera on stopRecording
          this.recorder.camera = camera;
  
          this.disabled = true;
        });
      },
      stopRec() {
        this.disabled = false;
        this.recorder.stopRecording(this.stopRecordingCallback);
      },
      captureCamera(callback) {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(function(camera) {
            callback(camera);
          })
          .catch(function(error) {
            alert("Unable to capture your camera. Please check console logs.");
            console.error(error);
          });
      },
      stopRecordingCallback() {
        const video = this.$refs["video"];
        video.src = video.srcObject = null;
        video.muted = false;
        video.volume = 1;
        video.src = URL.createObjectURL(this.recorder.getBlob());
  
        this.recorder.camera.stop();
        this.recorder.destroy();
        this.recorder = null;
      }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>