<script lang="ts" setup>
const isPlay = ref(false)
const canvasRef = ref()
const form = ref({
  suuid: '',
})
function handlePlay() {
  isPlay.value = !isPlay.value
}
function onVideoPlayDetect(videoRef: Ref<HTMLVideoElement>) {
  //
  detectVideo(videoRef.value, canvasRef.value)
}
watch(() => globalActiveKey.value, () => {
  unDetectVideo()
})
</script>

<template>
  <div class="mt-10px">
    <a-form :model="form" layout="inline">
      <a-form-item>
        <a-input v-model="form.suuid" placeholder="โปรดป้อน go2rtc url WebRTC SOURCE NAME ของคุณ" class="w-full"/>
      </a-form-item>
      <a-form-item>
        <button @click="handlePlay()" class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
          เล่น / หยุด
        </button>
      </a-form-item>
    </a-form>
    <div v-if="isPlay" class="relative h-full w-full">
      <VideoRtc :suuid="form.suuid" @play="onVideoPlayDetect" playsinline/>
      <canvas ref="canvasRef" class="absolute top-0 w-full h-full" :width="inputShape[1]" :height="inputShape[2]" />
    </div>
  </div>
</template>
